import React, { useState, useEffect } from "react";
import FormControlLabel from "@mui/material/FormControlLabel";
import { useTheme, Box, Grid, Stack, Button, IconButton, Checkbox } from "@mui/material";
import { useLocalStorage } from "../../hooks/useLocalStorage";
import { tokens } from "../../assets/theme";
import messages from "./messages";
import { FormattedMessage } from "react-intl";
import { fetchOeeDashboardDataTimeSpanDev, fetchOeeDashboardDataTimeSpan, fetchOeeGridDateIntervall } from "../home/hooks/fetchers";
import { fetchMachines } from "../../fetchers/machine";
import { useQuery } from "@tanstack/react-query";
import AxionControls from "../../components/controls/AxionControls";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';
import { DndContext, useSensor, useSensors } from "@dnd-kit/core";
import { arrayMove } from "@dnd-kit/sortable";
import { SortableContext, rectSortingStrategy } from "@dnd-kit/sortable";

export default function MachinesStatuses(props) {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const [companyKey, setCompanyKey] = useLocalStorage("companyKey", 1);
    const [timeSpan, setTimeSpan] = useState("currentshift");
    const [showMachineFilter, setShowMachineFilter] = useState(false);
    const [selectedMachines, setSelectedMachines] = useLocalStorage("machinesStatusSelectedMachines" , []);
    const [showFields, setShowFields] = useState(false);
    const [avalibleFields, setAvalibleFields] = React.useState(["TAK", "T", "A", "K", "Last CT", "Avg CT", "Ideal CT"]);
    const [selectedFields, setSelectedFields] = useLocalStorage("machinesStatusSelectedFields" , ["TAK"]);
    const [isFullscreen, setIsFullscreen] = useState(false);
    const [items, setItems] = useState([]);
    const [machinesSortOrder, setMachinesSortOrder] = useLocalStorage("machinesStatusSortOrder" , []);

    const toggleFullscreen = () => {
        setIsFullscreen(!isFullscreen);
    };

    let filteredData = [];

    const timespanQuery = useQuery({
        queryKey: ["oeedata", timeSpan],
        queryFn: () => fetchOeeDashboardDataTimeSpanDev(timeSpan, companyKey),
        staleTime: 30000,
        refetchInterval: 30000,
        enabled: true,
    });

    useEffect(() => {
        const itemsData = timespanQuery.data?.filter((item) => selectedMachines.includes(item.machineName));
        if(itemsData != null) {
            if(machinesSortOrder.length !== 0) {
                itemsData.sort((a, b) => {
                    return machinesSortOrder.indexOf(a.id) - machinesSortOrder.indexOf(b.id);
                });
            }
            setItems(itemsData);
            setMachinesSortOrder(itemsData.map((item) => item.id));
        }
    }, [timespanQuery.isFetching, selectedMachines]);

    const handleMachineChange = (machine) => {
        setSelectedMachines((prev) => prev.includes(machine) ? prev.filter((item) => item !== machine) : [...prev, machine]);
    };

    const handleFieldsChange = (field) => {
        setSelectedFields((prev) =>
        prev.includes(field)
            ? prev.filter((item) => item !== field)
            : [...prev, field]
            );
    };

    if (timespanQuery.isLoading) {
        return <AxionControls.AxionBackdrop open={true} />
    }
    
    filteredData = timespanQuery.data?.filter((item) => selectedMachines.includes(item.machineName));
    const filteredMachinesData = timespanQuery.data?.map((item) => { return { id: item.id, machineName: item.machineName }});
    
    const handleDragEnd = (event) => {
        const { active, over } = event;
        if(active == null || over == null) { return; }

        if (active.id !== over.id) {
            setItems((prevItems) => {
                const oldIndex = prevItems.findIndex((item) => item.id === active.id);
                const newIndex = prevItems.findIndex((item) => item.id === over.id);
                return arrayMove(prevItems, oldIndex, newIndex);
            });

            setMachinesSortOrder((prevItems) => {
                const oldIndex = prevItems.indexOf(active.id);
                const newIndex = prevItems.indexOf(over.id);
                return arrayMove(prevItems, oldIndex, newIndex);
            });
        }
    };

    return (
        <Box m="20px" 
            sx={{ 
                minHeight: '90vh', 
                backgroundColor: colors.primary[700] 
            }} 
            className={`component-container ${isFullscreen ? "fullscreen" : ""}`}>
            <Box>
                <Grid container spacing={0} sx={{ paddingBottom: "40px" }}>
                    <Box mt={3} marginLeft={2} >
                        <IconButton onClick={toggleFullscreen}>
                            {isFullscreen ? <FullscreenExitIcon /> : <FullscreenIcon />}
                        </IconButton>
                        <FilterAltIcon />
                        <Button
                            sx={{ color: colors.primary[200] }}
                            onClick={() => setShowMachineFilter((prev) => !prev)}
                        >
                        <FormattedMessage {...messages.machinesFilter} />{" "}
                        {showMachineFilter ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                        </Button>
                        <Button
                            sx={{ color: colors.primary[200] }}
                            onClick={() => setShowFields((prev) => !prev)}
                        >
                        <FormattedMessage {...messages.fieldsToShow} />{" "}
                        {showFields ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                        </Button>
                        {showMachineFilter && (
                        <Box mb={2} pl={2}>
                            {filteredMachinesData?.map((machine) => (
                            <FormControlLabel
                                key={machine.machineName}
                                control={
                                <Checkbox
                                    sx={{
                                        color: "secondary",
                                        '&.Mui-checked': {
                                        color: colors.dashbordGreen[500],
                                        },
                                    }}
                                    checked={selectedMachines.includes(machine.machineName)}
                                    onChange={() => handleMachineChange(machine.machineName)}
                                />
                                }
                                label={machine.machineName}
                            />
                            ))}
                        </Box>
                        )}
                        {showFields && (
                        <Box mb={2} pl={2}>
                            {avalibleFields?.map((field) => (
                            <FormControlLabel
                                key={field}
                                control={
                                <Checkbox
                                    sx={{
                                        color: "secondary",
                                        '&.Mui-checked': {
                                        color: colors.dashbordGreen[500],
                                        },
                                    }}
                                    checked={selectedFields.includes(field)}
                                    onChange={() => handleFieldsChange(field)}
                                />
                                }
                                label={
                                    field === "TAK" ? 
                                        <FormattedMessage {...messages.oee} /> : 
                                    field === "T" ? 
                                        <FormattedMessage {...messages.a} /> : 
                                    field === "A" ? 
                                        <FormattedMessage {...messages.p} /> : 
                                    field === "K" ? 
                                        <FormattedMessage {...messages.q} />: 
                                    field}
                            />
                            ))}
                        </Box>
                        )}
                    </Box>
                    <DndContext onDragEnd={handleDragEnd}>
                        <SortableContext items={items} strategy={rectSortingStrategy}>
                            <Box 
                                sx={{
                                    display: "flex",
                                    justifyContent: "flex-start",
                                    alignItems: "center",
                                    gap: 2,
                                    p: 1,
                                    m: 1,
                                    width: "100%",
                                    flexWrap: "wrap",
                                }}>
                                { items != null && items.length > 0 && 
                                    items.map((item, index) => ( 
                                        <AxionControls.AxionMachineCard 
                                            key={item.id}
                                            id={item.id}                                        
                                            item={item} 
                                            fields={selectedFields} /> 
                                    )) }   
                            </Box>
                        </SortableContext>
                    </DndContext>
                </Grid>
            </Box>

        </Box>
    );
}
